import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from './config';
// eslint-disable-next-line camelcase
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';

const storedAccessToken = localStorage.getItem('accessToken');

let decodedEmail = '';
let decodedPhone = '';
let decodedRole = '';
if (storedAccessToken) {
  const decodedToken = jwtDecode(storedAccessToken);

  decodedEmail = decodedToken.email;
  decodedPhone = decodedToken.phone;
  decodedRole = decodedToken.role;
}

const initialState = {
  profile: {},
  phoneConfirmed: false,

  email: decodedEmail,
  phone: decodedPhone,
  role: decodedRole,
  accessToken: storedAccessToken || '',
  refreshToken: localStorage.getItem('refreshToken') || '',
  loading: false,
  isSuccess: false,
  errors: [],
  profilePicture: {}
};

export const signin = createAsyncThunk(
  'auth/signin',
  async (body, { rejectWithValue }) => {
    try {
      const params = {
        email: body.email,
        phone: body.phone,
        password: body.password
      };

      const response = await api.post(
        process.env.REACT_APP_BACKEND_URL + '/auth/signin',
        params,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.data.role !== 'CLIENT') {
        return rejectWithValue({ message: 'NOT_CLIENT' });
      }
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);

      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getProfile = createAsyncThunk(
  'user/profile',
  async (__, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get(`/user/profile`);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const UpdateUser = createAsyncThunk(
  'profile/UpdateUser',
  async ({ auth, formData }, { rejectWithValue }) => {
    try {
      const response = await api.put('/user/profile', formData, {
        headers: {
          Authorization: auth,
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const UpdateUserBasicInfo = createAsyncThunk(
  'profile/UpdateUserBasicInfo',
  async ({ auth, formData }, { rejectWithValue }) => {
    try {
      const response = await api.put('/user/profile/basicInfo', formData, {
        headers: {
          Authorization: auth,
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const UpdateUserPhone = createAsyncThunk(
  'profile/UpdateUserPhone',
  async ({ auth, formData }, { rejectWithValue }) => {
    try {
      const response = await api.put('/user/profile/Phone', formData, {
        headers: {
          Authorization: auth,
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const verifyupdatePhoneClient = createAsyncThunk(
  'signUp/verifyupdatePhoneClient',
  async (codeData, { rejectWithValue }) => {
    try {
      console.log(codeData);

      const response = await api.post('user/verify-codePhone-client', codeData);
      console.log(response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updatePictureUser = createAsyncThunk(
  'profile/updatePicture',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api.post('/user/profile-picture', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      window.location.reload();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePassword = createAsyncThunk(
  'user/updatePassword',
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const params = {
        old_password: body.old_password,
        password: body.password,
        passwordConfirm: body.passwordConfirm
      };
      const response = await api.put(`/user/change-password`, params);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
const authSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    logout: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      localStorage.clear();
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signin.pending, (state) => {
        state.loading = true;
      })
      .addCase(signin.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        if (!payload?.accessToken || !payload?.refreshToken) {
          state.isSuccess = false;
          state.errors = ['AccessToken and/or refreshToken not found'];
        } else {
          state.accessToken = payload.accessToken;
          state.refreshToken = payload.refreshToken;
          const decodedToken = jwtDecode(payload.accessToken);
          state.email = decodedToken.email;
          state.phone = decodedToken.phone;
          state.role = decodedToken.role;
        }
        state.loading = false;
      })
      .addCase(signin.rejected, (state, { payload }) => {
        if (payload?.statusCode === 400) {
          state.errors = [payload.message];
          toast.error(payload.message);
        }
        state.isSuccess = false;
        state.loading = false;
      })
      .addCase(getProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfile.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.profile = payload;

        //document.cookie = `company=${state.profile.company._id}; domain=${process.env.REACT_APP_COOKIES_HOST}; path=/;`;
        state.loading = false;
      })
      .addCase(getProfile.rejected, (state, { payload }) => {
        if (payload?.statusCode === 400) {
          state.errors = payload?.message;
        }
        state.isSuccess = false;
        state.loading = false;
      })
      .addCase(UpdateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateUser.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.profile = {
          ...state.profile,
          ...payload
        };
        state.loading = false;
      })
      .addCase(UpdateUser.rejected, (state, { payload }) => {
        if (payload?.statusCode === 400) {
          state.errors = payload?.message;
        }
        state.isSuccess = false;
        state.loading = false;
      })
      .addCase(UpdateUserBasicInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateUserBasicInfo.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.profile = {
          ...state.profile,
          ...payload
        };
        state.loading = false;
      })
      .addCase(UpdateUserBasicInfo.rejected, (state, { payload }) => {
        if (payload?.statusCode === 400) {
          state.errors = payload?.message;
        }
        state.isSuccess = false;
        state.loading = false;
      })
      .addCase(UpdateUserPhone.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateUserPhone.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.profile = {
          ...state.profile,
          ...payload
        };
        state.loading = false;
      })
      .addCase(UpdateUserPhone.rejected, (state, { payload }) => {
        if (payload?.statusCode === 400) {
          state.errors = payload?.message;
        }
        state.isSuccess = false;
        state.loading = false;
      })
      .addCase(updatePictureUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePictureUser.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.profilePicture = payload; // Update the profile picture data
        state.loading = false;
      })
      .addCase(updatePictureUser.rejected, (state, { payload }) => {
        if (payload?.statusCode === 400) {
          state.errors = payload?.message;
        }
        state.isSuccess = false;
        state.loading = false;
      })
      .addCase(updatePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.isSuccess = true;
        state.loading = false;
      })
      .addCase(updatePassword.rejected, (state, { payload }) => {
        if (payload?.statusCode === 400) {
          state.errors = payload?.message;
        }
        state.isSuccess = false;
        state.loading = false;
      })
      .addCase(verifyupdatePhoneClient.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.response = null;
      })
      .addCase(verifyupdatePhoneClient.fulfilled, (state, action) => {
        state.isLoading = false;
        //console.log(action.payload)
        state.response = action.payload;
        state.phoneConfirmed = true; // Set isSignupFulfilled to true
      })
      .addCase(verifyupdatePhoneClient.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.phoneConfirmed = false;
      });
  }
});
export const { logout } = authSlice.actions;

export default authSlice.reducer;
