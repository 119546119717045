import api from './config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';

const initialState = {
  companies: [],
  company: null,
  loading: false,
  isSuccess: false,
  message: '',
  errors: [],
  favoris: {}
};
export const fetchCompanies = createAsyncThunk(
  'company/fetchCompanies',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get('/company/getAll');
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        console.log(error);
        throw error;
      }
      console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchCompanyByName = createAsyncThunk(
  'company/fetchCompanyByName',
  async ({ message }, { rejectWithValue, dispatch }) => {
    try {
      console.log(message);
      const response = await api.get(`/company/getByName/${message}`);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        console.log(error);
        throw error;
      }
      console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchCompanyById = createAsyncThunk(
  'company/fetchCompanyById',
  async ({ companyid }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get(`/company/${companyid}`);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        console.log(error);
        throw error;
      }
      console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
export const addFavoris = createAsyncThunk(
  'company/addFavoris',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post(`/user-favoris/add-favoris/${id}`);
      return response;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        console.log(error);
        throw error;
      }
      console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
export const removeFavoris = createAsyncThunk(
  'company/removeFavoris',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.delete(`/user-favoris/remove-favoris/${id}`);
      return response;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        console.log(error);
        throw error;
      }
      console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
export const getFavoris = createAsyncThunk(
  'company/getFavoris',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get(`/user-favoris/get-liste-favoris`);

      return response;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        console.log(error);
        throw error;
      }
      console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
const companiesSlice = createSlice({
  name: 'claims',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCompanies.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.companies = payload?.data || [];
        state.recordsTotal = payload?.recordsTotal || 0;
        state.loading = false;
      })
      .addCase(fetchCompanies.rejected, (state, { payload }) => {
        if (payload?.statusCode === 400) {
          state.errors = payload?.message;
        }
        state.isSuccess = false;
        state.loading = false;
      })
      .addCase(fetchCompanyByName.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCompanyByName.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.company = payload || null;
        state.loading = false;
      })
      .addCase(fetchCompanyByName.rejected, (state, { payload }) => {
        if (payload?.statusCode === 400) {
          state.errors = payload?.message;
        }
        state.isSuccess = false;
        state.loading = false;
      })
      .addCase(fetchCompanyById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCompanyById.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.company = payload || null;
        state.loading = false;
      })
      .addCase(fetchCompanyById.rejected, (state, { payload }) => {
        if (payload?.statusCode === 400) {
          state.errors = payload?.message;
        }
        state.isSuccess = false;
        state.loading = false;
      })
      .addCase(addFavoris.pending, (state) => {
        state.loading = true;
      })
      .addCase(addFavoris.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.message = 'Company added to favorites successfully!';
        state.loading = false;
      })
      .addCase(addFavoris.rejected, (state, { payload }) => {
        if (payload?.statusCode === 400) {
          state.errors = payload?.message;
        }
        state.isSuccess = false;
        state.loading = false;
      })
      .addCase(removeFavoris.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeFavoris.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.message = 'Company removed from favorites successfully!';
        state.loading = false;
      })
      .addCase(removeFavoris.rejected, (state, { payload }) => {
        if (payload?.statusCode === 400) {
          state.errors = payload?.message;
        }
        state.isSuccess = false;
        state.loading = false;
      })
      .addCase(getFavoris.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFavoris.fulfilled, (state, { payload }) => {
        state.isSuccess = true;

        state.favoris = payload.data || null;
        state.loading = false;
      })
      .addCase(getFavoris.rejected, (state, { payload }) => {
        if (payload?.statusCode === 400) {
          state.errors = payload?.message;
        }
        state.isSuccess = false;
        state.loading = false;
      });
  }
});
export default companiesSlice.reducer;
