import React from 'react';

const MessageParser = ({ children, actions }) => {
  const parse = (message) => {
    const lowerCaseMessage = message.toLowerCase();

    if (lowerCaseMessage.includes('réclamation')) {
      actions.handleMakeAComplaint();
    } else if (lowerCaseMessage.includes('suivre')) {
      actions.handleFollowUpComplaint();
    } else if (lowerCaseMessage.includes('information')) {
      actions.handleGeneralInfo();
    } else if (lowerCaseMessage.includes('agent')) {
      actions.handleSpeakToAgent();
    } else {
      actions.handleOther();
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions
        });
      })}
    </div>
  );
};

export default MessageParser;
