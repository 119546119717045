import React from 'react';
import './options.css';

const Options = (props) => {
  const options = [
    {
      text: 'Passer une réclamation',
      handler: props.actionProvider.handleMakeAComplaint,
      id: 1
    },
    {
      text: 'Suivre une réclamation existante',
      handler: props.actionProvider.handleFollowUpComplaint,
      id: 2
    },
    {
      text: 'Obtenir des informations générales',
      handler: props.actionProvider.handleGeneralInfo,
      id: 3
    },
    {
      text: 'Parler à un agent',
      handler: props.actionProvider.handleSpeakToAgent,
      id: 4
    },
    {
      text: 'Autre',
      handler: props.actionProvider.handleOther,
      id: 5
    }
  ];

  const buttonsMarkup = options.map((option) => (
    <button key={option.id} onClick={option.handler} className="option-button">
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export default Options;
